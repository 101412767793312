import { Typography, Grid, Paper, Container } from '@material-ui/core';

// ----------- Images -------------
import generateMultiMessageVideo from 'assets/multiSignetSeal.mp4';
import generateSingleMessageVideo from 'assets/OneTimeSignetSeal.mp4';
import generateSingleFileVideo from 'assets/oneTimeFileUpload.mp4';

// ------- Styles -------
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({

    videoPaper: {
        padding: "30px"
    },

    videoContainer: {
        marginTop: "20px",
        textAlign: "center",
        position: "relative",
        paddingBottom: "56.25%", /* 16:9 */
        height: "0"
      },
    
    videoContainerIframe: {
        position: "absolute",
        top: "0",
        left: "0",
        width: "100%",
        height: "100%",
        borderStyle: "solid",
        borderWidth: "0px 1px"
    }

}));

function HowToUseSignetVideos() {
    const classes = useStyles();

    return (
        <Container className={classes.background} maxWidth="lg" justify="center" align="center">
            <Grid container spacing={3}>
                <Grid item xs >
                    <Paper elevation={3} className={classes.videoPaper}>
                        <Typography variant='h4' align="center" color="textPrimary" gutterBottom> Create a one time use message</Typography>
                        <div className={classes.videoContainer}>
                            <video preload="metadata" playsInline controls className={classes.videoContainerIframe} src={generateSingleMessageVideo} type="video/mp4" alt="Using signet seal to generate and then test a single use" onLoad={()=>{this.player.seek(0)}}/>
                        </div>
                    </Paper>
                </Grid>
            </Grid>
            <Grid container spacing={3}>
                <Grid item xs >
                    <Paper elevation={3} className={classes.videoPaper}>
                        <Typography variant='h4' align="center" color="textPrimary" gutterBottom> Create a multiple use message with an 8 hour expiration </Typography>
                        <div className={classes.videoContainer}>
                            <video preload="metadata" playsInline controls className={classes.videoContainerIframe} src={generateMultiMessageVideo} type="video/mp4" alt="Using signet seal to generate and then test a multi-use message that expires in 8 hours"/>
                        </div>
                    </Paper>
                </Grid>
            </Grid>
            <Grid container spacing={3}>
                <Grid item xs >
                    <Paper elevation={3} className={classes.videoPaper}>
                        <Typography variant='h4' align="center" color="textPrimary" gutterBottom> Send a file with a one time use link </Typography>
                        <div className={classes.videoContainer}>
                            <video preload="metadata" controls className={classes.videoContainerIframe} src={generateSingleFileVideo} type="video/mp4" alt="Using signet seal to generate and then test a multi-use message that expires in 8 hours" />
                        </div>
                    </Paper>
                </Grid>
            </Grid>
        </Container>
    )
}

export default HowToUseSignetVideos;