import axios from 'axios';

// Need to get rid of Content-Disposition header on s3 or we will have to store the file name

// https://stackoverflow.com/questions/7428831/javascript-rename-file-on-download
const downloadFile = (url, deleteurl, setFileDownloaded, fileName) => {
  axios.get(
      url, 
      {
        headers: {
          "Content-Type": "application/octet-stream"
        },
        responseType: "blob"
      }
  )
    .then(response => {
      const a = document.createElement("a");
      const url = window.URL.createObjectURL(response.data);
      console.log(response.data)
      a.href = url;
      a.download = fileName;
      a.click();

      if(!(deleteurl === null)){
          axios.delete(
            deleteurl, 
            {
              headers: {
                "Content-Type": "application/octet-stream"
              },
              responseType: "blob"
            }
          )
            .then(response => {
              setFileDownloaded(true)
            })
      }
    })
    .catch(err => {
      console.log("error", err);
    });
};

export default downloadFile;